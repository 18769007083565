<template>
  <el-dialog
    :visible="exportReportTo"
    :title="$t('label.reportexport')"
    :before-close="exportBoxCancels"
    :close-on-click-modal="false"
    width="490px"
  >
    <div class="center_center" v-loading="exportLoad">
      <!-- <div class="title">导出视图</div> -->
      <div class="center_centercontent">
        <div
          class="each_content"
          @click="cur = 1"
          :class="{ active: cur == 1 }"
        >
          <p>{{ $t("label.report.export.format.title") }}</p>
          <p>{{ $t("vue_label_report_information") }}</p>
          <svg class="icon" aria-hidden="true" v-show="cur == 1">
            <use href="#icon-selectAttachment"></use>
          </svg>
        </div>
        <div
          class="each_content"
          @click="cur = 2"
          :class="{ active: cur == 2 }"
        >
          <p>{{ $t("vue_label_report_detailsonly") }}</p>
          <p>
            {{ $t("c2436", { number: exportReportMax }) }}
          </p>
          <svg class="icon" aria-hidden="true" v-show="cur == 2">
            <use href="#icon-selectAttachment"></use>
          </svg>
        </div>
      </div>
      <div class="select_page" v-if="cur == 2">
        <div class="show_page">
          <div>{{ $t("label.recordamount") }}：</div>
          <div>{{ totalSize }}{{ $t("label.item") }}</div>
        </div>
        <div class="choose_page">
          <!-- 导出页数：第{0}页/{1}页 -->
          <div>{{ $t("vue_label_report_exportpages") }}</div>
          <el-input v-model="pageNum" placeholder></el-input>
          <div>
            {{ $t("label.brief.page") }}&nbsp;/&nbsp;{{
              totalSize === 0 ? 1 : totalPages
            }}{{ $t("label.brief.page") }}
          </div>
        </div>
        <!-- 导出数量：{0}条 -->
        <div class="show_page">
          <div>{{ $t("vue_label_report_exportquantity") }}</div>
          <div>
            {{ totalSize > exportReportMax ? exportReportMax : totalSize
            }}{{ $t("label.item") }}
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="exportBoxCancels">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="exportConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    reportId: {
      // 报表id
      type: String,
      default: "",
    },
    totalPages: {
      // 总页数
      type: [Number, String],
      default: "",
    },
    totalSize: {
      // 总条数
      type: Number,
      default: 0,
    },
    exportReportTo: {
      // 控制弹框显示与否
      type: Boolean,
      default: false,
    },
    exportLoad: {
      // 控制加载
      type: Boolean,
      default: false,
    },
    cloneParamsObject: {
      type: Object,
      default: () => ({}),
    },
    // 是否导出保存过报表
    iscloneReport: {
      type: String,
      default: "",
    },
    // 报表导出最大条数
    exportReportMax: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cur: 1, // 导出方式
      pageNum: 1, // 当前导出页数
      exportData: 0, // 导出条数
    };
  },
  updated() {
    if (this.pageNum == 1) {
      if (this.totalSize < this.exportReportMax) {
        this.exportData = this.totalSize;
      } else {
        this.exportData = this.exportReportMax;
      }
    }
  },
  watch: {
    // 导出最大条数
    exportReportMax: {
      handler(val) {
        if (this.pageNum == this.totalPages) {
          // 最后一页
          this.exportData = this.totalSize - (this.pageNum - 1) * val;
        } else if (this.pageNum < 1 || this.pageNum > this.totalPages) {
          // 输入小于1或者大于总页数
          this.exportData = "";
        } else {
          this.exportData = val;
        }
      },
      deep: true,
      immediate: true,
    },
    pageNum: function () {
      if (this.pageNum == this.totalPages) {
        // 最后一页
        this.exportData =
          this.totalSize - (this.pageNum - 1) * this.exportReportMax;
      } else if (this.pageNum < 1 || this.pageNum > this.totalPages) {
        // 输入小于1或者大于总页数
        this.exportData = "";
      } else {
        this.exportData = this.exportReportMax;
      }
    },
  },
  methods: {
    // 确认导出按钮
    exportConfirm() {
      // 修改报表编辑项要先保存，否则直接导出
      if (this.cloneParamsObject?.scope) {
        this.$emit("firstSaveExportReport");
      } else {
        this.exportBoxConfirm();
      }
    },
    //导出
    async exportBoxConfirm() {
      /*-- 以下情况可导出：
        1.选择的第一种方式；导出最大条数2k
        2.选择的第二种方式，但需要区分总条数是否为0；
          总条数为0：当前页数为1，总页数为1；
          总条数大于0：当前页数小于等于总页数；
          导出最大条数5w||5k
      */
      if (
        this.cur == 1 ||
        (this.cur == 2 &&
          this.pageNum >= 1 &&
          ((this.totalSize > 0 && this.pageNum <= this.totalPages) ||
            (this.totalSize === 0 && this.pageNum === 1)))
      ) {
        // 选择的第一种导出方式或者第二种导出方式但是输入页数正常
        this.$emit("exportBoxConfirm");
        // 导出条数就控制在2000，只需改变导出页数即可
        let address = "/report/exportReportDetail"; //详情导出
        let address1 = "/report/exportReportData"; //格式化导出
        let url = `${
           window.Glod["ccex-apitsf"] + "/api"
        }${address}?accessToken=${this.$CCDK.CCToken.getToken()}&reportId=${
          this.reportId
        }&page=${this.pageNum}&pageSize=${
          this.exportReportMax
        }&exportFileType=.${
          this.exportFileType
        }`;

        let url1 = `${
           window.Glod["ccex-apitsf"] + "/api"
        }${address1}?accessToken=${this.$CCDK.CCToken.getToken()}&reportId=${
          this.reportId
        }&exportFileType=.${
          this.exportFileType
        }`;
        if (this.cur == 1) {
          window.open(url1);
          this.$emit("exportBoxCancels");
        } else {
          window.open(url);
          this.$emit("exportBoxCancels");
        }
      } else {
        this.$message.warning(
          `${this.$i18n.t("CloudCCCRM_ST_Info_TypeIn_001")}1-" ${
            this.totalPages
          } "${this.$i18n.t("vue_label_report_pagenumber")}`
        );
      }
    },
    //取消导出
    exportBoxCancels() {
      this.$emit("exportBoxCancels");
    },
  },
};
</script>

<style lang="scss" scoped>
.center_center {
  .title {
    margin-bottom: 8px;
    color: black;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  .center_centercontent {
    display: flex;
    .each_content {
      border: 0.5px solid #e7eaec;
      min-width: 192px;
      min-height: 167px;
      border-radius: 4px;
      font-size: 12px;
      padding: 0 18px;
      padding-top: 30px;
      cursor: pointer;
      position: relative;
      word-break: break-word; // 英文换行
      p:first-child {
        font-size: 14px;
      }
      p:last-child {
        font-size: 12px;
      }
      img,
      svg {
        width: 34px;
        height: 34px;
        position: absolute;
        right: -1px;
        top: -1px;
      }
    }
    .each_content:first-child {
      margin-right: 20px;
    }
    .active {
      border: 1px solid #4e8df7;
      box-shadow: 0 0 3px 0 #fff;
      border-radius: 4px;
    }
  }
  .select_page {
    margin-top: 22px;
    text-align: left;
    font-size: 12px;
    padding-left: 2px;
    color: #080707;
    .show_page {
      display: flex;
    }
    .show_page:last-child {
      margin-top: 16px;
    }
    .choose_page {
      margin-top: 16px;
      display: flex;
      align-items: center;
      position: relative;
      ::v-deep .el-input {
        width: 55px;
        margin: 0 4px;
        .el-input__inner {
          height: 20px;
          border-radius: 2px;
        }
      }
      .error_info {
        position: absolute;
        top: 22px;
        left: 58px;
        color: #c23934;
      }
    }
  }
}
</style>