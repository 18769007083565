<!--
  删除报表弹框
-->
<template>
  <el-dialog
    :visible="deleteReportTo"
    :title="$t('vue_label_report_deletereport')"
    :before-close="deleteReportCancel"
    :close-on-click-modal="false"
    width="490px"
  >
    <div class="center_center">
      <div>
        {{
          $t("label.file.folder.delete3", {
            foldername: reportInfos.reportLabel,
          })
        }}
      </div>
      <div>{{ $t("vue_label_norm_undone") }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deleteReportCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="deleteReportConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deleteReportApi } from "../api";
import debounce from "lodash.debounce";

export default {
  props: {
    reportInfos: {
      // 报表信息
      type: Object,
      default: () => {},
    },
    getDataLists: {
      type: Function,
      default: () => {},
    },
    deleteReportTo: {
      // 弹框显示隐藏
      type: Boolean,
      default: false,
    },
    confirmdelete: {
      // 是否要删除图表
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    deleteReportCancel() {
      this.$emit("deleteReportCancel");
    },
    /* 删除报表:
        confirmdelete	否	string	确认删除，不填默认为否； 
        当有图表以此视图为数据源时，且此参数不为true时，会提示有图表引用无法删除，
        点击确定可以再调这个接口传此参数为true，即可连图表一起删除
        添加防抖debounce
    */
    deleteReportConfirm: debounce(
      async function () {
        let params = {
          id: this.reportInfos.reportId,
          confirmdelete: this.confirmdelete,
        };
        let dataObject = await deleteReportApi(params).catch((error) => {
          this.$emit("deleteReportConfirms", error.returnInfo);
        });
        if (dataObject && dataObject.result) {
          this.$emit("deleteReportCancel");
          if (this.getDataLists) {
            // 列表页的删除，重新获取报表列表
            this.getDataLists();
          }
          this.$router.push({
            path: "/reportObject/reportList",
            query: {
              type: 'deleteReport'
            },
          });
          this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
        }
      },
      3000,
      { leading: true, trailing: false }
    ),
  },
};
</script>

<style lang="scss" scoped>
.center_center {
  div:first-child {
    color: #686868;
  }
  div:last-child {
    color: #ff3c32;
  }
}
</style>