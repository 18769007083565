import axios from '@/config/httpConfig'
   
/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get('/user/getUserInfo', data)
  } else {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    })
  }
}

// 报表列表页面
// 获取用户有无报表相关的权限的结果
export function getReportEnableList(data) {
  return axios.post('/report/getReportEnableList', data)
}
// 获取报表首页列表
export function getDataList(data) {
  return axios.post('/report/getReportList', data)
}
//设置报表列表列
export function setReportListCols(data) {
  return axios.post('/report/setReportListCols', data)
}
// 重命名报表
export function updateReportName(data) {
  return axios.post('/report/updateReportName', data)
}
// 复制报表
export function saveCopyReport(data) {
  return axios.post('/report/saveCopyReport', data)
}
// 删除报表
export function deleteReportApi(data) {
  return axios.post('/report/deleteReport', data)
}
// 获取报表文件夹
export function getFolder(data) {
  return axios.post('/report/getReportFolders', data)
}
// 报表文件夹新增页信息
export function getNewReportFolder() {
  return axios.post('/report/getNewReportFolder')
}
// 新增文件夹
export function addReportFolder(data) {
  return axios.post('/report/addReportFolder', data)
}
// 获取报表文件夹信息
export function getFolderInfo(data) {
  return axios.post('/report/getReportFolderInfo', data)
}
// 编辑文件夹
export function updateReportFolder(data) {
  return axios.post('/report/updateReportFolder', data)
}
// 删除报表文件夹
export function deleteReportFolder(data) {
  return axios.post('/report/deleteReportFolder', data)
}

// 选择数据源
// 获取主对象列表
export function getObjectList(data) {
  return axios.post('/object/getObjectList', data)
}
// 获取相关对象列表
export function getRelateObjectList(data) {
  return axios.post('/object/getRelateObjectList', data)
}

// 编辑报表页面
// 新建报表时根据对象id获取筛选条件字段列表
export function getConditionsByObjectIds(data) {
  return axios.post('/report/getConditionsByObjectIds', data)
}
//已有报表进行编辑时根据报表Id获取筛选条件字段列表 
export function getConditionsByReportId(data) {
  return axios.post('/report/getConditionsByReportId', data)
}
//获取当前人的角色及下属角色树
export function getRoleTree(data) {
  return axios.post('/report/getRoleTree', data)
}
//获取报表持续时间
export function getReportDurationList(data) {
  return axios.post('/report/getReportDurationList', data)
}
// 获取报表详细信息
export function getReportDataByDetail(data) {
  return axios.post('/report/getReportDataByDetail', data)
}
//保存报表订阅
export function saveReportSubscribe(data) {
  return axios.post('/subscribe/saveReportSubscribe', data)
}
// 取消报表订阅
export function unsubscribe(data) {
  return axios.post('/subscribe/unsubscribe', data)
}
// 查询报表订阅
export function gotoSubscribe(data) {
  return axios.post('/subscribe/gotoSubscribe', data)
}
// 获取矩阵报表内部单元格详情信息
export function getReportDetail(data) {
  return axios.post('/report/getReportDetail', data)
}
// 保存报表
export function saveReportData(data) {
  return axios.post('/report/saveReport', data)
}
//获取选项列表值
export function getSelectValue(data) {
  return axios.post('/report/getSelectValue', data)
}
//报表文件夹置顶和取消置顶接口
export function topFolder(data) {
  return axios.post('/report/topFolder', data)
}
//获取币种信息
export function getCurrencyInfo(data) {
  return axios.post('/currency/getCurrencyInfo', data)
}
// 获取图表信息
export function getChartDataByReport(data) {
  return axios.post('/chart/getChartDataByReport', data)
}
// 根据记录类型查询报表
export function queryReprtRecordtypeid(data) {
  return axios.post('/report/chart/queryReprtRecordtypeid', data)
}
// 根据报表id查询图表数据
export function getChartDataByReportForObjectDetail(data) {
  return axios.post('/chart/getChartDataByReportForObjectDetail', data)
}
// ------------------自定义SQL-----------------------
// 获取报表的优化SQL语句
export function getCustomReportSql(data) {
  return axios.post('/report/getCustomReportSql', data)
}
// 验证并保存优化SQL
export function verificationCustomReportSql(data) {
  return axios.post('/report/verificationCustomReportSql', data)
}
// 优化后的报表详情查询
export function getOptimizationReportDataByDetail(data) {
  return axios.post('/report/getOptimizationReportDataByDetail', data)
}