// 报表文件夹权限

import { getFolder } from "./api";

export default {
  data() {
    return {
      allFolderlist: [], // 文件夹列表
    }
  },
  methods: {
    // 获取报表文件夹权限：编辑，删除
    getReportFolderPermissions(folderId, allFolders) {
      let isedit = false; // 是否可编辑
      let isdelete = false; // 是否可删除
      if (allFolders.length > 0) {
        // 根据报表所在文件夹id匹配该文件夹信息
        let folderData = allFolders.find((item) => item.id == folderId);
        if (folderData) {
          if ((folderData.name === this.$i18n.t('message.myselfreport')) || (folderData.name === this.$i18n.t('label.notfiledpublicreport'))) {
            // 若是文件夹为我的个人自定义或是未归档，则具有编辑删除权限；
            isedit = true;
            isdelete = true;
          } else {
            isedit = JSON.parse(folderData.isedit);
            isdelete = JSON.parse(folderData.isdelete);
          }
        }
      }
      return {
        isedit: isedit,
        isdelete: isdelete,
      }
    },
    // 获取报表文件夹
    async getFolders() {
      var params = {
        searchKeyWord: '',
      };
      let { data } = await getFolder(params);
      this.allFolderlist = data.allFolderlist;
    },

  },
}